.cart-info{
    transition: all .3s;
    pointer-events: none;
    opacity: 0;
    position: absolute;
    width: 360px;
    background-color: #fff;
    padding: 16px;
    z-index: 3;
    top: 131px;
    right: 40px;
    border-radius: 8px;
    box-shadow: 0px 0 15px 4px rgba(138, 33, 33, 0.15);
    //box-shadow: 0 0 .5em .25em rgba(0,0,0,.15);
    &._active{
        opacity: 1;
        pointer-events: unset;
    }
    @media screen and (min-width: 1501px){
        right: calc((100vw - 1400px) / 2);
        transform: translateX(67px);
    }
    &:before{
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        width: 12px;
        height: 12px;
        background-image: url(../img/triangle.svg);
        @include bgImgSize(100%);
        transform: translate(-50%,-100%);
        filter: drop-shadow(0px 6px 15px rgba(138, 33, 33, 0.08));
    }
}

.cart-product{
    margin-top: 16px;
    display: grid;
    grid-template-columns: 80px calc(100% - 88px);
    gap: 16px 8px;
    &__img{
        img{
            width: 100%;
            display: block;
        }
    }
    &__title{
        min-height: 80px;
        display: flex;
        align-items: center;
        font-size: 18px;
        line-height: 133%;
        font-weight: 600;
    }
    &__price{
        font-size: 28px;
        line-height: 129%;
        font-weight: 700;
    }
    &__btn{
        grid-column: 1/-1;
        font-size: 14px;
        height: 47px;
    }
}

.modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $fullHeight;
    z-index: 100;
    text-align: center;
    overflow-y: auto;
    padding: 16px;
    opacity: 0;
    pointer-events: none;
    &._active{
        opacity: 1;
        pointer-events: unset;
    }
    &::after {
        display: inline-block;
        vertical-align: middle;
        width: 0;
        height: 100%;
        content: '';
    }
    &__bg{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: $fullHeight;
        background: rgba(60, 58, 58, 0.70);
    }
    &__dialog{
        display: inline-block;
        vertical-align: middle;
        text-align: left;
        width: 360px;
        padding: 16px 16px 32px;
        max-width: 100%;
        position: relative;
        z-index: 2;
        background-color: #fff;
        box-shadow: 0px 6px 15px 0px rgba(138, 33, 33, 0.08);
        border-radius: 8px;
    }
    &__top{
        padding-right: 30px;
    }
}

.modal-product{
    margin-top: 16px;
    &__img{
        padding: 0 38px;
        img{
            width: 100%;
            display: block;
        }
    }
    &__content{
        display: grid;
        grid-template-columns: calc(100% - 128px) 120px;
        gap: 8px;
        align-items: center;
    }
    &__title{
        font-size: 18px;
        line-height: 133%;
        font-weight: 600;
    }
    &__price{
        font-size: 28px;
        line-height: 129%;
        font-weight: 700;
        text-align: right;
    }
}

.modal-form{
    margin-top: 16px;
    @include grid100gap(16px);
    &__btn{
        font-size: 14px;
        height: 47px;
        padding: 0;
    }
}


@media screen and (max-width: 1560px){
    .cart-info {
        transform: unset;
        &:before{
            left: unset;
            right: 108px;
            transform: translateY(-100%);
        }
    }
}


@media screen and (max-width: 1500px){
    .cart-info{
        &:before{
            right: 95px;
        }
    }
}


@media screen and (max-width: 1400px){
    .cart-info{
        &:before{
            right: 70px;
        }
    }
}


@media screen and (max-width: 1024px){
    .cart-info{
        top: 70px;
        width: 326px;
        &:before{
            right: 7px;
        }
        &__top{
            .text20{
                font-size: 18px;
            }
        }
    }

    .cart-product{
        grid-template-columns: 100%;
        padding-right: 54px;
        position: relative;
        &__img{
            display: none;
        }
        &__btn{
            background-image: url(../img/arrow-right.svg);
            @include bgImgSize(24px);
            grid-column: unset;
            display: flex;
            width: 48px;
            height: 48px;
            position: absolute;
            right: 0;
            top: 0;
            span{
                display: none;
            }
        }
        &__title{
            min-height: unset;
            font-size: 16px;
            line-height: 150%;
            display: inline;
        }
        &__price{
            display: inline;
            font-size: 18px;
            line-height: 138%;
            margin-left: 6px;
        }
    }
}


@media screen and (max-width: 576px){
    .cart-info{
        right: 20px;
        width: calc(100% - 40px);
    }

    .modal-product{
        &__img{
            padding: 0 22.5px;
        }
        &__content{
            gap: unset;
            display: flex;
            justify-content: space-between;
        }
        &__price{
            white-space: nowrap;
        }
    }
}
