.collection{
  padding: 34px 0;
  &__slider{
    position: relative;
  }
  .swiper{
    margin-left: -8px;
    width: calc(100% + 8px);
    &-wrapper{
      padding: 0 !important;
    }
    @media screen and (min-width: 1440px){
      &-slide{
        width: 318px;
      }
    }
    &-button{
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      &.swiper-button-disabled{
        path{
          fill: #CCC7C7;
        }
      }
    }
    &-prev{
      left: -40px;
    }
    &-next{
      right: -40px;
    }
  }
  .home-page{
    .link{
      white-space: nowrap;
      flex-shrink: 0;
    }
  }
}

.catalog-detail{
  padding: 32px 0;
  &__content{
    margin-top: 36px;
  }
}

.tabs{
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0px 6px 15px 0px rgba(138, 33, 33, 0.08);
}

._tab{
  padding: 0 24px;
  display: flex;
  align-items: center;
  height: 40px;
  font-size: 14px;
  line-height: 120%;
  text-transform: uppercase;
  position: relative;
  transition: all .3s;
  cursor: pointer;
  &:before{
    content: '';
    position: absolute;
    height: 3px;
    width: 100%;
    left: 0;
    bottom: 0;
    background-color: $primary;
    transition: inherit;
    opacity: 0;
  }
  &._active{
    font-weight: 700;
    color: $primary;
    pointer-events: none;
    &:before{
      opacity: 1;
    }
  }
  &:hover{
    color: $primary;
  }
}

._tab-content{
  display: none;
  &._active{
    display: block;
  }
}

.mob-detail{
  &__show{
    display: none;
  }
}

.prop-item{
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 32px;
  font-size: 14px;
  line-height: 125%;
  &:not(:last-child){
    border-bottom: 1px solid #F3F3F3;
  }
  &__title{
    color: #3C3A3A;
    line-height: inherit;
  }
  &__desc{
    font-weight: 600;
    line-height: inherit;
  }
}

.available{
  @include grid100gap(16px);
}

.available-group{
  &__title{
    font-size: 16px;
    line-height: 200%;
    font-weight: 600;
  }
  &__desc{
    font-size: 16px;
    line-height: 125%;
    color: $darkgray;
    display: flex;
    align-items: center;
    img{
      width: 24px;
      flex-shrink: 0;
      margin-right: 8px;
    }
    strong{
      line-height: 200%;
      font-weight: 600;
      color: $black;
      margin-left: 8px;
    }
  }
}

.catalog-item{
  padding-bottom: 32px;
  &__grid{
    display: grid;
    grid-template-columns: 52.5% 44.6%;
    gap: 2.9%;
  }
}

.gallery{
  overflow: hidden;
  position: relative;
  .swiper{
    &-wrapper{
      padding: 0 !important;
    }
    &-button{
      width: 52px;
      height: 52px;
      border-radius: 50%;
      background-color: #fff;
      @include center;
      &:hover{
        path{
          fill: $black;
        }
      }
      &:after{
        display: none;
      }
      &-prev{
        left: 24px;
      }
      &-next{
        right: 24px;
      }
      svg{
        width: 24px;
        height: auto;
      }
    }
    &-pagination{
      display: none;
    }
  }
  &__big{
    position: relative;
    .gallery__img {
      padding-bottom: 70.89%;
      img {
        max-width: calc(100% - 144px);
        max-height: calc(100% - 144px);
      }
    }
  }
  &__small{
    margin-top: 36px;
    width: calc(100% + 21px);
    .swiper{
      &-slide{
        width: 20%;
        padding-right: 21px;
        &-thumb-active{
          .gallery__img{
            border-color: $primary;
          }
        }
      }
    }
    .gallery__img{
      padding-bottom: 72.9%;
      img{
        max-width: calc(100% - 32px);
        max-height: calc(100% - 32px);
      }
      &:hover{
        cursor: pointer;
        border-color: $primary;
      }
    }
  }
  &__img{
    position: relative;

    background-color: $bg;
    border: 1px solid $bg;
    transition: all .3s;
    img{
      @include centerAbsolute;
    }
  }
}

.product-favourite{
  position: absolute;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: #fff;
  @include center;
  top: 24px;
  right: 24px;
  z-index: 2;
  .star{
    position: static;
    &:not(._active){
      background-image: url(../img/star2.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 28px auto;
    }
  }
}

.product-info{
  padding: 24px;
  background-color: $bg;
  &__top{
    display: flex;
    align-items: center;
  }
  &__item{
    display: flex;
    align-items: center;
    @include lastM(0 24px 0 0);
    &-title,
    &-desc{
      font-size: 14px;
      line-height: 171%;
    }
    &-title{
      color: $darkgray;
      margin-right: 8px;
    }
    &-desc{
      font-weight: 600;
    }
    img{
      width: 24px;
      flex-shrink: 0;
      margin-right: 8px;
    }
  }
  &__title{
    margin-top: 12px;
  }
  &__price{
    margin-top: 24px;
  }
  .props{
    margin-top: 24px;
    @include grid100gap(8px);
    &__nav{
      display: flex;
      align-items: center;
      min-height: 32px;
    }
  }
  .prop-item{
    border: none !important;
    background-color: #fff;
    padding: 8px;
  }
  .link{
    font-size: 14px;
    cursor: pointer;
  }
}

.colors{
  margin-top: 24px;
  &__items{
    margin-top: 8px;
    display: grid;
    grid-template-columns: repeat(3,calc(100% / 3 - 16px / 3));
    gap: 8px;
  }
  &__nav{
    display: flex;
    margin-top: 8px;
  }
  &:not(._all-colors){
    .color-field{
      &:nth-child(3)~.color-field{
        display: none;
      }
    }
  }
  &._all-colors{
    .colors__nav{
      display: none;
    }
  }
}

.color-field{
  position: relative;
  input{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer;
    &:hover+.color-field__wrap,
    &:checked+.color-field__wrap{
      border-color: $primary;
    }
  }
  &__wrap{
    padding: 0 4px;
    border-radius: 4px;
    border: 1px solid #E5D5D5;
    background: #fff;
    display: flex;
    align-items: center;
    transition: all .3s;
  }
  &__img{
    width: 60px;
    height: 60px;
    @include center;
    margin-right: 8px;
    flex-shrink: 0;
    img{
      max-width: 100%;
      max-height: 100%;
      display: block;
    }
  }
  &__title{
    font-size: 14px;
    line-height: 172%;
    font-weight: 600;
  }
}

.product-nav{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  .link{
    font-size: 18px;
    margin-top: 16px;
    svg{
      width: 16px;
      height: auto;
    }
  }
}

.cart-nav{
  width: 100%;
  &__btn{
    padding: 0;
    height: 100%;
    font-size: 14px;
    width: 100%;
    @include center;
    cursor: pointer;
  }
  &__added,
  &__not-added{
    height: 47px;
    transition: all .3s;
    overflow: hidden;
  }
  &__added{
    display: grid;
    grid-template-columns: repeat(2,calc(50% - 4px));
    gap: 8px;
    transition: all .3s;
  }
  .cartcalc{
    height: 100%;
  }
  &:not(._added){
    .cart-nav__added{
      height: 0;
    }
  }
  &._added{
    .cart-nav__not-added{
      height: 0;
    }
  }
}

.btn{
  &.bg-green{
    &:not(:hover){
      background: $green;
    }
  }
}

.cartcalc{
  border-radius: 4px;
  border: 1px solid $green;
  background: #fff;
  @include center;
  height: 47px;
  button{
    width: 24px;
    cursor: pointer;
    svg{
      width: 100%;
      height: auto;
    }
    &:hover{
      cursor: pointer;
      path{
        fill: $green;
      }
    }
  }
  input{
    width: 44px;
    height: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
  }
}

.buy-section{
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 30;
  box-shadow: 0 6px 15px 0 rgba(138, 33, 33, 0.08);
  background-color: #fff;
  transition: all .6s;
  transform: translateY(-150%);
  &._show{
    transform: translateY(0);
  }
  &__inner{
    display: flex;
    align-items: center;
    padding: 10px 0;
  }
  &__img{
    width: 65px;
    height: 65px;
    flex-shrink: 0;
    @include center;
    margin-right: 24px;
    img{
      max-width: 100%;
      max-height: 100%;
      display: block;
    }
  }
  &__right{
    display: flex;
    align-items: center;
    margin-left: auto;
    padding-left: 20px;
  }
  .product-info__price{
    margin: 0;
    white-space: nowrap;
  }
  .cart-nav{
    width: 262px;
    margin-left: 50px;
  }
}

._anchor{
  position: relative;
  &:before{
    content: '';
    position: absolute;
    bottom: 100%;
    left: 0;
    margin-bottom: 125px;
    width: 100%;
    height: 2px;
  }
}


@media screen and (max-width: 1440px){
  .collection{
    overflow: hidden;
    .swiper{
      overflow: unset;
      &-button{
        display: none;
      }
    }
  }

  .catalog-detail{
    padding: 24px 0 35px;
  }

  .catalog-item{
    &__grid{
      grid-template-columns: repeat(2,calc(50% - 10px));
      gap: 20px;
    }
  }

  .gallery{
    .swiper{
      &-button{
        &-prev{
          left: 20px;
        }
        &-next{
          right: 20px;
        }
      }
    }
    &__small{
      margin-top: 24px;
      width: calc(100% + 13px);
      .swiper{
        &-slide{
          padding-right: 13px;
        }
      }
    }
  }

  .product-favourite{
    right: 20px;
    top: 20px;
  }
}


@media screen and (max-width: 1200px){
  .product-info{
    .props{
      margin-top: 20px;
    }
  }

  .colors{
    margin-top: 20px;
    &__items{
      grid-template-columns: repeat(2,calc(50% - 4px));
    }
    &:not(._all-colors){
      .color-field{
        &:nth-child(2)~.color-field{
          display: none;
        }
      }
    }
  }

  .product-nav{
    margin-top: 20px;
  }
}


@media screen and (max-width: 1024px){
  .gallery{
    &__small{
      margin-top: 20px;
      .swiper-slide{
        width: 102px;
        padding-right: 20px;
      }
    }
  }

  .product-info{
    &__price{
      margin-top: 20px;
    }
  }
}


@media screen and (max-width: 992px){
  .collection{
    padding: 35px 0;
    .home-page{
      padding: 0 0 24px;
    }
  }

  .catalog-detail{
    padding: 18px 0 25px;
  }

  .product-info{
    padding: 16px;
    &__top{
      @media screen and (min-width: 701px){
        @include grid100gap(8px);
      }
    }
  }

  .colors{
    @media screen and (min-width: 701px){
      &__items{
        grid-template-columns: 100%;
      }
    }
  }

  .product-favourite{
    right: 8px;
    top: 8px;
    width: 36px;
    height: 36px;
  }

  .gallery{
    .swiper{
      &-button{
        width: 36px;
        height: 36px;
        svg{
          width: 18px;
        }
        &-prev{
          left: 8px;
        }
        &-next{
          right: 8px;
        }
      }
    }
  }
}


@media screen and (max-width: 768px){
  .catalog-detail{
    padding: 0 0 24px;
    .container{
      padding: 0;
    }
    .tabs{
      display: none;
    }
    &__content{
      margin: 0;
    }
    ._tab-content{
      display: block;
    }
  }

  .mob-detail{
    margin-top: 2px;
    background: #FFF;
    &._active{
      .mob-detail__show{
        color: $primary;
        font-weight: 700;
        img{
          transform: rotate(-180deg);
        }
      }
    }
    &__show{
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 44px;
      padding: 5px 40px;

      box-shadow: 0px 6px 15px 0px rgba(138, 33, 33, 0.08);
      font-size: 12px;
      line-height: 125%;
      text-transform: uppercase;
      img{
        width: 12px;
        transition: all .3s;
      }
    }
    &__hidden{
      padding: 10px 40px;
      display: none;
    }
  }

  .gallery{
    &__big{
      .gallery__img{
        img{
          max-width: calc(100% - 96px);
          max-height: calc(100% - 96px);
        }
      }
    }
  }
}


@media screen and (max-width: 700px){
  .catalog-item{
    overflow: hidden;
    &__grid{
      grid-template-columns: 100%;
      gap: 8px;
      width: calc(100% + 40px);
      margin-left: -20px;
    }
  }

  .product-info{
    .props{
      &__nav{
        display: none;
      }
    }
  }

  .product-nav{
    margin-top: 16px;
    .cart-nav{
      display: none;
    }
    .link{
      margin: 0;
      font-size: 14px;
      svg{
        width: 12px;
      }
    }
  }

  .buy-section{
    top: unset;
    transform: unset;
    bottom: 0;
    box-shadow: 0 -6px 15px 0 rgba(138, 33, 33, 0.08);
    &__img,
    &__title,
    .product-info__price{
      display: none;
    }
    &__right{
      width: 100%;
      margin: 0;
      padding: 0;
    }
    .cart-nav{
      margin: 0;
      width: 100%;
    }
  }

  .catalog-item{
    padding-bottom: 0;
  }

  .product-favourite{
    top: 16px;
    right: 20px;
  }
}


@media screen and (max-width: 576px){
  .collection{
    padding: 24px 0;
    .home-page{
      padding: 0 0 16px;
    }
    .h2 {
      font-size: 18px;
      line-height: 139%;
    }
  }

  .prop-item{
    font-size: 12px;
  }

  .available-group{
    &__title,
    &__desc{
      font-size: 12px;
    }
  }

  .text16{
    font-size: 12px;
  }

  .mob-detail{
    &__show,
    &__hidden{
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .gallery{
    &__big{
      .gallery__img{
        padding-bottom: 65.8%;
      }
    }
    &__small{
      display: none !important;
    }
    .swiper{
      &-button{
        display: none;
      }
      &-pagination{
        display: flex;
        justify-content: center;
      }
    }
  }

  .product-info{
    &__top{
      justify-content: space-between;
    }
    &__price{
      margin-top: 16px;
      font-size: 32px;
    }
    .props{
      margin-top: 16px;
    }
  }

  .colors{
    margin-top: 16px;
  }
}
