@import "../vars.scss";
.breadcrumbs {
    padding: 32px 0;
    &__item {
        font-size: 14px;
        line-height: 171%;
        position: relative;
        font-weight: 400;
        display: inline;
        color: $darkgray;
        &:last-child {
            font-weight: 600;
            color: $black;
        }
        &:not(:last-child) {
            margin-right: 16px;
            padding-right: 28px;
            &:after {
                content: '';
                position: absolute;
                z-index: 1;
                width: 12px;
                height: 12px;
                background-image: url(../img/bread-arrow.svg);
                @include bgImgSize(100%);
                top: 4px;
                right: 0;
            }
        }
        a{
            color: inherit;
            &:hover{
                color: $black;
            }
        }
    }
}


@media screen and (max-width: 576px){
    .breadcrumbs{
        display: flex;
        align-items: center;
        padding: 20px;
        width: calc(100% + 40px);
        margin-left: -20px;
        overflow-x: auto;
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
        &__item{
            display: flex;
            align-items: center;
            white-space: nowrap;
            font-size: 12px;
            &:not(:last-child) {
                margin-right: 12px;
                padding-right: 24px;
            }
        }
    }
}
