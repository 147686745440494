.catalog{
  padding-bottom: 32px;
  &__grid{
    display: grid;
    grid-template-columns: repeat(4,calc(25% - 54px / 4));
    gap: 18px;
  }
  &__more{
    margin-top: 32px;
    width: 100%;
    height: 48px;
    border-radius: 8px;
    background-color: #F3F3F3;
    font-size: 14px;
    font-weight: 600;
    color: $black;
    @include center;
    &:hover{
      background-color: $black;
      color: #fff;
      cursor: pointer;
      path{
        fill: #fff;
      }
    }
    svg{
      margin-left: 8px;
      width: 13px;
      height: auto;
    }
  }
}

.catalog-card{
  padding: 52px 19px 32px;
  border: 1px solid #E5D5D5;
  border-radius: 8px;
  position: relative;
  .star{
    top: 8px;
    right: 8px;
  }
  &__img{
    width: calc(100% + 14px);
    margin-left: -7px;
    flex-shrink: 0;
    @include imgCover(83.6%);
  }
  &__title{
    margin-bottom: 4px;
  }
  &__count{
    font-size: 16px;
    line-height: 169%;
    color: $darkgray;
    margin: 0 0 16px;
    text-align: center;
  }
  &__price{
    margin-bottom: 0;
    font-size: 28px;
    line-height: 120%;
    font-weight: 700;
    &-wrap{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0;
    }
    &-title{
      font-size: 14px;
      line-height: 171%;
      margin-right: 4px;
    }
  }
  &__btn{
    font-size: 14px;
    height: 47px;
    width: 100%;
    &._active{
      background-color: #248D1A;
      ._view1{
        display: none;
      }
      ._view2{
        display: block;
      }
    }
    ._view2{
      display: none;
    }
  }
  &__btn2{
    height: 48px;
    width: 100%;
    justify-content: center;
    font-size: 14px;
    margin-top: 8px;
  }
  &._not-available{
    .catalog-card__title{
      color: $gray;
    }
    .catalog-card__price{
      font-size: 14px;
      line-height: 171%;
    }
  }
}

.categories{
  padding-bottom: 20px;
  .home-page{
    padding-top: 0;
  }
  &__grid{
    display: grid;
    grid-template-columns: repeat(6,calc(100% / 6 - 100px / 6));
    gap: 20px;
  }
}

.category-card{
  display: block;
  &:hover{
    .category-card__img{
      box-shadow: none;
      transform: scale(1.02);
    }
  }
  &__img{
    transition: all .3s;
    box-shadow: 0px 6px 15px 0px rgba(138, 33, 33, 0.08);
    border-radius: 8px;
    border: 1px solid #CCC;
    @include imgCover(100%);
    background-color: #fff;
    img{
      border-radius: inherit;
    }
  }
  &__title{
    margin-top: 16px;
    text-align: center;
    font-size: 14px;
    line-height: 171%;
    color: $primary;
    font-weight: 700;
    span{
      color: $black;
      white-space: nowrap;
    }
  }
}

.collection2{
  .swiper-slide{
    border: none;
    padding: 0;
  }
}

.catalog-page{
  .home-page{
    padding-top: 0;
  }
  &__grid{
    display: grid;
    grid-template-columns: 276px calc(100% - 296px);
    gap: 20px;
  }
  .catalog__grid{
    margin-top: 32px;
  }
  @media screen and (min-width: 901px){
    .catalog__grid{
      grid-template-columns: repeat(3,calc(100% / 3 - 12px));
    }
  }
}

.filter{
  position: relative;
  padding: 24px 16px;
  background-color: #fff;
  box-shadow: 0px 6px 15px 0px rgba(138, 33, 33, 0.08);
  &__title{
    font-size: 18px;
    line-height: 133%;
    font-weight: 600;
  }
  &__nav{
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(2,calc(50% - 4px));
    gap: 8px;
  }
  &__btn{
    font-size: 14px;
    cursor: pointer;
  }
  &__btn2{
    cursor: pointer;
    background-color: transparent;
    color: $gray;
    text-transform: unset;
    font-weight: 600;
    &:hover{
      background-color: transparent;
      color: $black;
    }
  }
  .close-btn{
    display: none;
  }
}

.filter-info{
  z-index: 10;
  left: 190px;
  top: 270px;
  position: absolute;
  width: 244px;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0px 6px 15px 0px rgba(138, 33, 33, 0.08);
  border-radius: 8px;
  &__title{
    font-size: 14px;
    line-height: 171%;
    b{
      font-weight: 700;
    }
  }
  .filter__nav{
    margin-top: 16px;
    @media screen and (min-width: 993px){
      grid-template-columns: 100%;
      .filter__btn2{
        display: none;
      }
    }
  }
}

.filter-group{
  margin-top: 8px;
  &._active {
    .filter-group__toggle {
      svg {
        transform: rotate(-180deg);
      }
    }
  }
  &__top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 32px;
    padding: 4px 8px;
  }
  &__title{
    font-size: 14px;
    line-height: 171%;
    font-weight: 400;
    color: $primary;
  }
  &__toggle{
    width: 12px;
    cursor: pointer;
    svg{
      transition: all .4s;
      width: 100%;
      height: auto;
    }
  }
  &__bottom{
    display: none;
  }
  &__checkboxes{
    &:not(._show-all){
      .filter-group__check:nth-child(5) ~ .filter-group__check{
        display: none;
      }
      &~.filter-group__more{
        display: block;
      }
    }
  }
  &__check{
    padding: 4px 8px;
  }
  &__more{
    display: none;
    padding: 4px 8px;
    font-size: 14px;
    line-height: 172%;
    cursor: pointer;
    color: $black;
    font-family: $font1;
    &:hover{
      color: $primary;
    }
  }
}

.filter-slider{
  .noUi-pips-horizontal{
    top: unset;
    bottom: 100%;
    padding: 0;
    height: 40px;
  }
  .noUi-value{
    font-size: 12px;
    color: $gray;
    &:nth-child(2),
    &:last-child{
      color: $darkgray;
    }
  }
  .noUi-marker{
    transform: translateY(36px);
    &-normal{
      display: none;
    }
    &-large{
      width: 0.5px;
      height: 16px !important;
      background: $black;
    }
  }
  &__item{
    padding-bottom: 8px;
    min-height: 56px;
    display: flex;
    align-items: flex-end;
  }
  .range-slider{
    margin: 0 auto;
    width: calc(100% - 16px);
    background-color: $gray;
    border: none;
    box-shadow: none;
    height: 8px;
    border-radius: 0;
    .noUi-connect{
      background-color: $primary;
    }
    .noUi-handle{
      border: none;
      box-shadow: none;
      @include bgImgSize(100%);
      width: 8px;
      height: 8px;
      top: 100% !important;
      right: -.0625em !important;
      border-radius: 0;
      transform: translateY(100%);
      &:before{
        opacity: 0;
        width: 400%;
        height: 100%;
        @include centerAbsolute;
      }
      &:after{
        display: none;
      }
      &-lower{
        background-image: url(../img/picker.svg);
      }
      &-upper{
        background-image: url(../img/picker2.svg);
      }
    }
  }
  &__inputs{
    display: grid;
    grid-template-columns: repeat(2,calc(50% - 12px));
    gap: 24px;
  }
  .fg{
    position: relative;
    input{
      border-radius: 4px;
      border: 1px solid #CED4DA;
      height: 28px;
      padding-left: 40px;
      color: #515356;
      font-size: 14px;
    }
    span{
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
      font-size: 14px;
      line-height: 171%;
      color: $gray;
    }
  }
}

.close-btn{
  width: 24px;
  position: absolute;
  top: 16px;
  right: 16px;
  &:hover{
    cursor: pointer;
    path{
      fill: $primary;
    }
  }
  svg{
    width: 100%;
    height: auto;
  }
}

.mob-filter__btn{
  display: none;
}

.btn{
  cursor: pointer;
}

.brands{
  padding: 0 24px;
  display: grid;
  grid-template-columns: repeat(5,calc(20% - 96px / 5));
  gap: 36px 24px;
}

.brand-card{
  display: flex;
  align-items: center;
}

.sizes{
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  &__item{
    margin: 16px 16px 0 0;
    height: 40px;
    padding: 0 16px;
    @include center;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #F3F3F3;
    box-shadow: 0px 6px 15px 0px rgba(138, 33, 33, 0.08);
    font-size: 14px;
    font-weight: 600;
    color: $black;
    &:hover{
      background-color: $primary;
      color: #fff;
      cursor: pointer;
    }
  }
}

.pagination{
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  border-radius: 8px;
  background-color: $bg;
  &__links{
    display: flex;
    align-items: center;
  }
  &__link{
    border-radius: 4px;
    padding: 0 3px;
    min-width: 24px;
    height: 24px;
    @include center;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    margin-right: 8px;
    color: $black;
    &:hover{
      color: $primary;
      path{
        fill: $primary;
      }
    }
    &._active{
      background-color: #fff;
      color: $gray;
      pointer-events: none;
    }
    &._dots{
      display: none;
    }
    svg{
      width: 12px;
      height: auto;
    }
  }
  &__more{
    cursor: pointer;
    font-size: 14px;
  }
}

.info{
  padding: 32px 0;
  &__grid{
    display: grid;
    grid-template-columns: repeat(2,calc(50% - 10px));
    gap: 20px;
  }
  .h2{
    line-height: 146%;
  }
  .text16{
    margin-top: 36px;
    @include grid100gap(20px);
  }
  &__nav{
    display: flex;
    margin-top: 36px;
  }
  &__more{
    color: $primary;
    font-weight: 600;
    svg{
      width: 16px;
      height: auto;
    }
  }
  &__img{
    img{
      width: 100%;
      display: block;
    }
  }
}

.selected-filters{
  display: flex;
  flex-wrap: wrap;
  &__item{
    margin: 10px 10px 0 0;
    display: flex;
    align-items: center;
    padding: 0 8px;
    color: #fff;
    background-color: $primary;
    height: 24px;
    border-radius: 12px;
    font-size: 12px;
  }
  &__remove{
    width: 16px;
    margin-left: 2px;
    flex-shrink: 0;
    cursor: pointer;
    svg{
      width: 100%;
      height: auto;
    }
  }
}

.catalog-nav{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  border-radius: 8px;
  background-color: $bg;
  margin-top: 32px;
}

.catalog-count{
  display: flex;
  align-items: center;
  &__title,
  &__item{
    font-size: 14px;
    line-height: 171%;
    font-weight: 600;
  }
  &__items{
    display: flex;
    align-items: center;
    margin-left: 18px;
  }
  &__item{
    border-radius: 4px;
    height: 24px;
    @include center;
    color: $black;
    @include lastM(0 8px 0 0);
    &:hover{
      color: $primary;
    }
    &._active{
      pointer-events: none;
      min-width: 24px;
      padding: 0 3px;
      color: $gray;
      background-color: #fff;
    }
  }
}


@media screen and (max-width: 1300px){
  .catalog{
    &__grid{
      grid-template-columns: repeat(3,calc(100% / 3 - 12px));
    }
  }

  .categories{
    &__grid{
      grid-template-columns: repeat(5,calc(20% - 16px));
    }
  }

  .brands{
    padding: 0;
    grid-template-columns: repeat(5,calc(20% - 96px / 5));
    gap: 24px;
  }

  .catalog-page{
    @media screen and (min-width: 901px){
      .catalog__grid{
        grid-template-columns: repeat(2,calc(50% - 9px));
      }
    }
  }

  .info{
    &__nav,
    .text16{
      margin-top: 24px;
    }
  }
}


@media screen and (max-width: 1000px){
  .catalog{
    &__grid{
      grid-template-columns: repeat(2,calc(50% - 9px));
    }
  }

  .categories{
    &__grid{
      grid-template-columns: repeat(4,calc(25% - 15px));
    }
  }
}


@media screen and (max-width: 992px){
  .catalog-page{
    &__grid{
      grid-template-columns: 100%;
      gap: 0;
    }
  }

  .mob-filter__btn{
    display: block;
    font-size: 14px;
    width: 100%;
  }

  .filter{
    transform: translateX(-120%);
    transition: all .3s;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 20;
    width: 100%;
    padding: 0;
    &__scroll{
      padding: 24px 16px 130px;
      height: $fullHeight;
      overflow-y: auto;
    }
    &._active{
      transform: translateX(0%);
      &~.filter__bg{
        display: block;
      }
    }
    .close-btn{
      display: block;
    }
    ._hide-mob{
      display: none;
    }
  }

  .filter-info{
    top: unset;
    left: 0;
    bottom: 0;
    width: 100%;
    position: fixed;
  }

  .brands,
  .sizes{
    width: calc(100% + var(--padding_sm) + var(--padding_sm));
    margin-left: calc(-1 * var(--padding_sm));
    padding: 0 var(--padding_sm);
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  .brands{
    display: flex;
    align-items: center;
  }

  .brand-card{
    flex-shrink: 0;
    width: 146px;
    margin-right: 16px;
  }

  .sizes{
    flex-wrap: nowrap;
    &__item{
      flex-shrink: 0;
    }
  }

  .pagination{
    &__link{
      &._hide-mob{
        display: none;
      }
    }
  }

  .info{
    &__grid{
      grid-template-columns: 100%;
      gap: 16px;
    }
    .h2{
      line-height: 133%;
    }
    .text16{
      font-size: 14px;
    }
    &__more{
      font-size: 14px;
      svg{
        width: 12px;
      }
    }
  }

  .catalog-nav{
    margin-bottom: 8px;
  }

  .catalog-count{
    display: none;
  }
}


@media screen and (max-width: 900px){
  .categories{
    &__grid{
      grid-template-columns: repeat(3,calc(100% / 3 - 40px / 3));
    }
  }
}


@media screen and (max-width: 768px){
  .collection2{
    .swiper{
      &-slide{
        width: 276px;
      }
    }
  }
}


@media screen and (max-width: 700px){
  .home-page--mob{
    padding-bottom: 19px;
  }

  .catalog{
    &__grid{
      grid-template-columns: 100%;
    }
  }

  .categories{
    &__grid{
      grid-template-columns: repeat(2,calc(50% - 10px));
    }
  }
}


@media screen and (max-width: 576px){
  .sizes,
  .brands{
    width: calc(100% + var(--padding_xs) + var(--padding_xs));
    margin-left: calc(-1 * var(--padding_xs));
    padding: 0 var(--padding_xs);
  }
}
