.m-check{
  position: relative;
  input{
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    cursor: pointer;
  }
  input {
    &:checked+label {
      font-weight: 500;
      &::before {
        background-color: $primary;
        border-color: $primary;
        background-image: url(../img/check2.svg);
      }
    }
  }
  label{
    position: relative;
    cursor: pointer;
    display: block;
    padding-left: 36px;
    font-size: 14px;
    line-height: 24px;
    &:before{
      content: '';
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      transition: all .100s;
      width: 24px;
      height: 24px;
      border: 1px solid $gray;
      border-radius: 3px;
      @include bgImgSize(18px);
    }
  }
}

.fg{
  input{
    width: 100%;
    border-radius: 4px;
    border: 1px solid #CED4DA;
    height: 32px;
    padding: 0 16px;
    font-size: 14px;
    line-height: 171%;
    color: $black;
    &:focus{
      border-color: $primary;
    }
  }
  .nice-select{
    float: unset;
    width: 100%;
    height: unset;
    background-color: transparent !important;
    border: none !important;
    padding: 0;
    font-size: 1em;
    .current{
      padding: 0 20px 0 0;
      font-size: 14px;
      line-height: 171%;
      font-weight: 600;
      display: flex;
      align-items: center;
      background-image: url(../img/chevron-down.svg);
      background-repeat: no-repeat;
      background-position: right center;
      background-size: 12px auto;
    }
    &.open{
      .current{
        background-image: url(../img/chevron-up.svg);
      }
    }
  }
  .list{
    min-width: 100%;
    width: auto;
    margin-top: 2px;
    background: #FFFFFF;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05);
    border-radius: 4px;
    border: none;
    padding: 0;
    overflow-y: auto;
    max-height: 250px;
    @include scroll;
  }
  .option{
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    padding: 0 50px 0 12px;
    border: none !important;
    background-repeat: no-repeat;
    background-position: right .875em center;
    background-size: 1.25em auto;
    &.focus,
    &.selected,
    &:hover{
      background-color: rgba($primary,.2) !important;
      font-weight: 600 !important;
    }
    &.selected{
      background-color: $primary !important;
      color: #fff;
      background-image: url(../img/check2.svg);
    }
    &.disabled{
      display: none;
    }
  }
}
