@import "vars";
@import "mixins";
@import "ui/text";
@import "ui/inputs";
@import "page-blocks/breadcrumbs";
@import "page-blocks/popups";
@import "pages/catalog-item";
@import "pages/catalog";
html{
  scroll-behavior: smooth;
}
body{
  &.fixed-body{
    overflow-y: hidden;
  }
  &.fixed-body2{
    overflow-y: hidden;
    //padding-right: var(--scrollWidth);
  }
}

.favourite-icon{
  svg{
    max-width: unset;
    max-height: unset;
    width: 36px;
    height: auto;
  }
}
