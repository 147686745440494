.text28{
  font-size: 28px;
  line-height: 128%;
}

.text24{
  font-size: 24px;
  line-height: 167%;
}

.text20{
  font-size: 20px;
  line-height: 140%;
}

.text16{
  font-size: 16px;
  line-height: 169%;
  p{
    line-height: inherit;
  }
}

.bold-text{
  font-weight: 700;
}


@media screen and (max-width: 992px){
  .text28{
    font-size: 20px;
    line-height: 140%;
  }

  .text24{
    font-size: 16px;
  }
}
